<template>
   <vSelect :appendToBody="true" :options="options" id='mySelect'
            :style="getAppStyle()+'max-width: 100% !important; overflow: hidden;  font-size: 9pt; border: 1px solid #ccc !important;'" 
            v-model="value" :disabled="disabled" :multiple="multiple"  @input="$emit('input', value)">  
            

			<template :style="lockedStyle(locked)" #option="{ id, label, locked }">
      				<div z-index="999" :style="lockedStyle(locked)">{{label}}</div>
    	   </template>
    		
    		
    		<template v-if="!multiple" #selected-option="{ label }">
		      <div :style="getAppStyle()+'max-width: '+width+' !important; overflow: hidden; padding-left: 1pt; padding-right: 2pt;'" class='selectedOption'>{{ label }} </div>
		    </template>
			<template v-else #selected-option="{ label }">
		      <div  :style="'padding-left: 1pt; padding-right: 2pt;'" class='selectedOptionM'>{{ label }} </div>
		    </template>
		    
    		<template #no-options="{  }">
             <div :style="'font-size: 8pt; color: #666; position: relative; top: 0; margin:0;'">Sorry, no matching options</div>
             </template> 
            
   </vSelect>


</template>
<script>
import { getAppStyle, getBGStyle3, initAppMode } from '@/AppStyle.js';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  props: {
	  disabled: { type: Boolean, default: false },
	  multiple: { type: Boolean, default: false },
	  width: { type: String, default: '100%'},
	  value: Object,
	  options: Array
  },
 components : {
	 vSelect
  },
  data () {
    return {
    	getAppStyle
    }
  },
  methods: {
	  getWidth()
	  {
		  alert(document.querySelector('#myselect').clientWidth) 
		  return document.querySelector('#myselect').clientWidth-20;
	  },
	  
	  lockedStyle(lock)
	     {
	     	if ( lock )
	     	{
	     		return "color: #000; font-weight: bold; font-size: 8pt; margin: 0pt !important; height: 14pt !important; width: 100; padding: 0pt; background-color: #fcc;";
	     	}
	     	return "font-size: 8pt; height: 14pt;";
	     },
  },
  
  created() {
	  initAppMode();
  },
  watch: {
      
  }
 }
</script>
<style lang="scss" scoped>
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.iButton {

}
.selectedOption { 
   font-size: 12px; 
   //height: 18px !important; 
   max-width: calc(20vH);
   width: calc(20vH);
   margin-top: 2pt;
  
   margin-right: 2pt;
   white-space: nowrap;
   overflow: hidden !important;
   text-overflow: ellipsis !important;
}
.selectedOptionM { 
   font-size: 12px; 
   height: 18px !important; 
   max-width: calc(25vH);
   width: calc(10vH);
   margin-top: 2pt;
  
   margin-right: 2pt;
   white-space: nowrap;
   overflow: hidden !important;
   text-overflow: ellipsis !important;
}
li {
 background-color: red !important;
}
.vs__selected {
	background-color: transparent !important;
}
</style>