var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vSelect',{style:(_vm.getAppStyle()+'max-width: 100% !important; overflow: hidden;  font-size: 9pt; border: 1px solid #ccc !important;'),attrs:{"appendToBody":true,"options":_vm.options,"id":"mySelect","disabled":_vm.disabled,"multiple":_vm.multiple},on:{"input":function($event){return _vm.$emit('input', _vm.value)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var id = ref.id;
var label = ref.label;
var locked = ref.locked;
return [_c('div',{style:(_vm.lockedStyle(locked)),attrs:{"z-index":"999"}},[_vm._v(_vm._s(label))])]}},(!_vm.multiple)?{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('div',{staticClass:"selectedOption",style:(_vm.getAppStyle()+'max-width: '+_vm.width+' !important; overflow: hidden; padding-left: 1pt; padding-right: 2pt;')},[_vm._v(_vm._s(label)+" ")])]}}:{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('div',{staticClass:"selectedOptionM",style:('padding-left: 1pt; padding-right: 2pt;')},[_vm._v(_vm._s(label)+" ")])]}},{key:"no-options",fn:function(ref){return [_c('div',{style:('font-size: 8pt; color: #666; position: relative; top: 0; margin:0;')},[_vm._v("Sorry, no matching options")])]}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})}
var staticRenderFns = []

export { render, staticRenderFns }